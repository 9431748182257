exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-firma-js": () => import("./../../../src/pages/firma.js" /* webpackChunkName: "component---src-pages-firma-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-partnerzy-js": () => import("./../../../src/pages/partnerzy.js" /* webpackChunkName: "component---src-pages-partnerzy-js" */),
  "component---src-pages-taxi-klodawa-js": () => import("./../../../src/pages/taxi-klodawa.js" /* webpackChunkName: "component---src-pages-taxi-klodawa-js" */),
  "component---src-pages-taxi-kolbudy-js": () => import("./../../../src/pages/taxi-kolbudy.js" /* webpackChunkName: "component---src-pages-taxi-kolbudy-js" */),
  "component---src-pages-taxi-pruszcz-gdanski-lotnisko-js": () => import("./../../../src/pages/taxi-pruszcz-gdanski-lotnisko.js" /* webpackChunkName: "component---src-pages-taxi-pruszcz-gdanski-lotnisko-js" */),
  "component---src-pages-taxi-pszczolki-js": () => import("./../../../src/pages/taxi-pszczolki.js" /* webpackChunkName: "component---src-pages-taxi-pszczolki-js" */),
  "component---src-pages-taxi-rokitnica-js": () => import("./../../../src/pages/taxi-rokitnica.js" /* webpackChunkName: "component---src-pages-taxi-rokitnica-js" */),
  "component---src-pages-taxi-rotmanka-js": () => import("./../../../src/pages/taxi-rotmanka.js" /* webpackChunkName: "component---src-pages-taxi-rotmanka-js" */),
  "component---src-pages-taxi-straszyn-js": () => import("./../../../src/pages/taxi-straszyn.js" /* webpackChunkName: "component---src-pages-taxi-straszyn-js" */),
  "component---src-pages-taxi-tczew-js": () => import("./../../../src/pages/taxi-tczew.js" /* webpackChunkName: "component---src-pages-taxi-tczew-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-zakupy-na-telefon-pruszcz-js": () => import("./../../../src/pages/zakupy-na-telefon-pruszcz.js" /* webpackChunkName: "component---src-pages-zakupy-na-telefon-pruszcz-js" */)
}

